import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useHistory, useParams } from 'react-router';
import { CLAIM_FOR_RADIO, CRYPTO_SECRET_KEY, extractNumbersOnly, URLS, COMPANY_TYPE_RADIO, VIDEO_URLS } from 'src/_config';
import { useEventApi } from 'src/_common/hooks/actions/event/appEventApiHook';
import FormTextAreaInput from 'src/_common/components/form-elements/textarea/textareaInput';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Link } from 'react-router-dom';
import { useToaster } from 'src/_common/hooks/actions/common/appToasterHook';
import FormTextInput from 'src/_common/components/form-elements/textinput/formTextInput';
import FormMaskedInput from 'src/_common/components/form-elements/maskedInput/maskedInput';
import Heading from './Heading';
import { useAppEventAction } from 'src/_common/hooks/actions/event/appEventActionHook';
import { useAppShortCodeDetailsSelector, useAppShowAnnaModalSelector } from 'src/_common/hooks/selectors/eventSelector';
import debounce from 'lodash.debounce';
import { useAppUserDetailsSelector } from 'src/_common/hooks/selectors/userSelector';
import { toNumber } from 'lodash';
import RadioInput from 'src/_common/components/form-elements/radioinput/radioInput';
import AskAnnaModal from '../common/AskAnnaModal';
import SelectInput from 'src/_common/components/form-elements/selectinput/selectInput';

interface CreateClaimStep1FormValues {
    contact_person: string;
    contact_number: string;
    company_name: string;
    contact_email: string;
    // registered_address: string;
    street: string;
    city: string;
    country: string;
    postal_code: string;
    reg_no: string;
    director: any;
    phone_no: string;
    email: string;
    company_website: string;
    company_phone: string;
    company_type: string;
    first_Name_sc: string;
    last_Name_sc: string;
    email_sc: string;
    phone_sc: string;
}

const CreateClaimStep1Schema = yup.object().shape({
    contact_person: yup
        .string()
        .required('Contact Name is required'),
    company_name: yup
        .string()
        .required('Company Name is required'),
    // registered_address: yup
    //     .string()
    //     .required('Registered Address is required'),
    street: yup
        .string()
        .required('Street is required'),
    city: yup
        .string()
        .required('City is required'),
    country: yup
        .string()
        .required('Country is required'),
    postal_code: yup
        .string()
        .required('Postal Code is required'),
    contact_email: yup
        .string()
        .email('Please provide a valid email address')
        .required('Contact Email is required'),
    contact_number: yup
        .string()
        .required('Contact Number is required'),
    company_type: yup
        .string()
        .required('Company Type is required'),
    reg_no: yup
        .string()
        .when("company_type", {
            is: (value) => value == 2,
            then: yup.string().required("Registration Number is required")
        }),
    // .required('Registration Number is required'),
    director: yup
        .string()
        .nullable()
        .required('Director Name is required'),
    first_Name_sc: yup
        .string()
        .nullable()
        .notRequired()
        .optional(),
    last_Name_sc: yup
        .string()
        .nullable()
        .notRequired()
        .optional(),
    email_sc: yup
        .string()
        .nullable()
        .notRequired()
        .optional()
        .email('Please provide a valid email address'),
    phone_sc: yup
        .string()
        .nullable()
        .notRequired()
        .optional(),
    // phone_no: yup
    //     .string()
    //     .required('Contact Number is required'),
    // company_phone: yup
    //     .string()
    //     .required('Contact Number is required'),
    // email: yup
    //     .string()
    //     .email('Please provide a valid email address')
    //     .required('Company Email is required'),
    // company_website: yup
    //     .string()

})

const Cryptr = require('cryptr');
const cryptr = new Cryptr(CRYPTO_SECRET_KEY);

export default function CreateClaimStep1() {

    const { register, control, setValue, getValues, handleSubmit, errors, watch } = useForm<CreateClaimStep1FormValues>({
        resolver: yupResolver(CreateClaimStep1Schema),
        defaultValues: {
            contact_person: '',
            company_name: '',
            company_website: '',
            // registered_address: '',
            street: '',
            city: '',
            country: '',
            postal_code: '',
            contact_email: '',
            contact_number: '',
            company_type: '1',
            // company_phone: '',
            reg_no: '',
            director: '',
            // first_Name_sc: '',
            // last_Name_sc: '',
            // email_sc: '',
            // phone_sc: '',
            // phone_no: '',
            // email: '',
        },
    })

    const userDetails = useAppUserDetailsSelector()
    const eventApi = useEventApi()
    const eventAction = useAppEventAction()
    const toast = useToaster()
    const history = useHistory()
    const [incompleteClaimList, setIncompleteClaimList] = useState<any>()
    const shortCodeDetails = useAppShortCodeDetailsSelector()
    const [companyList, setCompanyList] = useState<any>()
    const [companyNameList, setCompanyNameList] = useState<any>()
    const [directorsList, setDirectorsList] = useState<any>()
    const [showList, setShowList] = useState<boolean>(false)
    const [isVerified, setIsVerified] = useState<number>(2)

    const { id } = useParams<any>();
    const claim_id = id ? cryptr.decrypt(id) : null;
    const claimNo = localStorage.getItem('claimNo') ? localStorage.getItem('claimNo') : 1;
    const anotherCheck = localStorage.getItem('another');
    const showAskAnnaModal = useAppShowAnnaModalSelector()

    const directorName = watch("director")

    const saveAndClose = () => {
        // let allFields = ["contact_person", "contact_number", "company_name", "registered_address", "reg_no", "phone_no", "email", "contact_email", "company_website", "company_phone"]
        // let allFields = ["contact_person", "company_name", "reg_no", "company_website", "contact_number", "contact_email", "registered_address", "company_type", "street", "city", "country", "postal_code", "first_Name_sc", "last_Name_sc", "email_sc", "phone_sc"]
        let allFields = ["contact_person", "company_name", "reg_no", "company_website", "contact_number", "contact_email",  "company_type", "street", "city", "country", "postal_code"]
        if (getValues("contact_number") && (extractNumbersOnly(getValues("contact_number")).length < 10)) {
            toast.error("Please enter valid contact number")
            return
        }
        if (!getValues("company_name")) {
            toast.error("Please enter company name")
            return
        }
        var parms: any = {}
        allFields.map((f: string) => {
            let x: any = getValues(f)
            console.log({ x })
            if ((f == "registered_address" && x == "") || (f == "reg_no" && x == "") || (f == "director" && x == "")) {
                parms[f] = x
            }
            if (x && x != "") {
                // parms[f] = (f == "contact_number") ? x.split(" ").join("") : x
                parms[f] = (f == "contact_number" || f == "phone_sc") ? extractNumbersOnly(x) : x
                // parms[f] = (f == "company_phone") || (f == "contact_number") ? x.replace(/\D/g, '').slice(-10) : x
            }
        })
        // if(isVerified!=2) {
        // parms["is_verified"] = isVerified
        // }
        step1Submit(parms, "close")
    }

    const onRegistrationFormSubmit = (values: CreateClaimStep1FormValues) => {
        console.log({ values })
        if (extractNumbersOnly(values.contact_number).length < 10) {
            toast.error("Please enter valid contact number")
            return
        }
        var parms: any = {
            contact_person: values.contact_person,
            company_name: values.company_name,
            reg_no: values.reg_no,//parseInt(values.reg_no),
            // director: isVerified && isVerified == 1 ? directorName.value : directorName,
            // director: directorName,
            company_website: values.company_website,
            // contact_number: values.contact_number.split(" ").join(""),
            contact_number: extractNumbersOnly(values.contact_number),
            // registered_address: values.registered_address,
            registered_address: values.street+", "+values.city+", "+values.postal_code+", "+values.country,
            contact_email: values.contact_email,
            company_type: values.company_type == '1' ? 1 : 2,
            // company_phone: values.company_phone != " " ? values.company_phone.split(" ").join("") : "",
            // contact_number: values.contact_number.replace(/\D/g, '').slice(-10),
            // phone_no: values.phone_no.replace(/\D/g, '').slice(-10),
            // email: values.email,
            street: values.street,
            city: values.city,
            country: values.country,
            postal_code: values.postal_code,
            // first_Name_sc: values.first_Name_sc,
            // last_Name_sc: values.last_Name_sc,
            // email_sc: values.email_sc,
            // phone_sc: extractNumbersOnly(values.phone_sc),
        }
        console.log(parms)
        // if(isVerified!=2) {
        // parms["is_verified"] = isVerified
        // }
        step1Submit(parms, "next")
    }

    const step1Submit = (parms: any, action: string) => {

        if (claim_id) {
            parms["id"] = claim_id
        }

        if (shortCodeDetails) {
            const type = shortCodeDetails.entity_type
            const id = shortCodeDetails.entity_id
            parms["introducer_id"] = (type == "3") ? 0 : id
            parms["introducer_type"] = (type == "3") ? null : (type == "2") ? "S" : "A"
            parms["campaign_id"] = (type == "3") ? id : 0
        }

        // if(isVerified!=2) {
        parms["is_verified"] = (isVerified == 2 || isVerified == 0) ? 0 : 1
        // }
        parms["director"] = (isVerified && isVerified == 1 && directorsList && directorsList.length) ? directorName.value : directorName
        console.log({ parms })

        eventApi.callCreateClaimStep1(parms, (message: string, resp: any) => {
            if (resp) {
                toast.success(message);
                localStorage.setItem("claim_id", resp.details.id)
                if (action == "next") {
                    history.push('/create-claim-2/' + (id ? id : cryptr.encrypt(resp.details.id)))
                }
                else if (action == "close") {
                    history.push(URLS.EVENT.MY_CLAIMS)
                    localStorage.removeItem("another")
                }
            } else {
                toast.error(message)
            }
        }, (message: string) => {
            toast.error(message)
        })
    }

    const getGetIncompleteList = () => {
        var params: any = {
            id: claim_id
        }
        eventApi.callClaimDetails(params, (message: string, resp: any) => {
            if (resp) {
                console.log(resp)
                setIncompleteClaimList(resp.data)
            }
        }, (message: string) => {
        })
    }

    const changeHandler = (e: any) => {
        //   setCharitySearchValue(event.target.value);
        if (e && e.length >= 1) {
            getCompanyList(e)
            // setShowList(true)
        }
        else {
            setIsVerified(2)
            resetCompanyInfo()
        }
    }

    const debouncedChangeHandler = useCallback(
        debounce(changeHandler, 500)
        , [])

    const setNameAddressFields = (comp: any) => {
        let reg_no = comp?.company_number
        let comp_address_line1 = comp?.registered_office_address?.address_line_1 ? comp?.registered_office_address?.address_line_1 : ''
        let comp_address_line2 = comp?.registered_office_address?.address_line_2 ? comp?.registered_office_address?.address_line_2 : ''
        let comp_locality = comp?.registered_office_address?.locality ? comp?.registered_office_address?.locality : ''
        let comp_region = comp?.registered_office_address?.region ? comp?.registered_office_address?.region : ''
        let comp_country = comp?.registered_office_address?.country ? comp?.registered_office_address?.country : ''
        let comp_postal_code = ''
        let comp_postal_code_only = comp?.registered_office_address?.postal_code ? comp?.registered_office_address?.postal_code : ''
        if (comp?.registered_office_address?.postal_code && comp_region && comp_country) {
            comp_postal_code = comp?.registered_office_address?.postal_code + ', ' + comp_region + ', ' + comp_country
        } else if (comp?.registered_office_address?.postal_code && (comp_region || comp_country)) {
            comp_postal_code = comp?.registered_office_address?.postal_code + ', ' + (comp_region ? comp_region : comp_country)
        } else if (comp?.registered_office_address?.postal_code && !comp_region && !comp_country) {
            comp_postal_code = comp?.registered_office_address?.postal_code
        } else {
            comp_postal_code = comp?.registered_office_address?.postal_code
        }

        let comp_address = (comp_address_line1 ? comp_address_line1 + ', ' : ' ') + (comp_address_line2 ? comp_address_line2 + ', ' : ' ') + (comp_locality ? comp_locality + ', ' : ' ') + comp_postal_code
        setValue('company_name', comp.company_name)
        // setValue('registered_address', comp_address)
        setValue('street', comp_address_line1)
        setValue('city', comp_locality)
        setValue('country', comp_country)
        setValue('postal_code', comp_postal_code_only)
        setValue('reg_no', reg_no)
        getCompanyList(comp.company_name, true)
        getDirectorsList(reg_no)
        // setCompanyNameList(null)
        setShowList(false)
    }

    const getDirectorsList = (company_number: any) => {
        eventApi.callGetDirectorsList({ company_number },
            (message: string, resp: any) => {
                setDirectorsList(resp.items.filter((x: any) => x.officer_role == "director"))
            }, (message: string) => {
                // toast.error(message)
            })
    }

    const getCompanyNames = (q: any) => {
        eventApi.callGetCompanyNames({ q },
            (message: string, resp: any) => {
                if (resp) {
                    setCompanyList(resp.data)
                }
            }, (message: string) => {

            })
    }

    const resetCompanyInfo = () => {
        setValue('registered_address', '')
        setValue('reg_no', '')
        setValue('director', '')
        // setDirectorsList(null)
        setValue('street', '')
        setValue('city', '')
        setValue('country', '')
        setValue('postal_code', '')
    }

    const getCompanyList = (company_name_includes: any, check?: boolean) => {
        eventApi.callGetCompanyList({ company_name_includes },
            (message: string, resp: any) => {
                let cNames = resp.map((x: any) => x.company_name)
                if (resp.length) {
                    console.log(resp)
                    setCompanyNameList(resp)
                    setShowList(true)
                    // if(getValues('company_type')=="2") {
                    //     console.log("2")
                    //     setIsVerified(0)
                    //     // resetCompanyInfo()
                    // }
                    // else {
                    //     console.log("3")
                    //     setIsVerified(2)
                    //     // resetCompanyInfo()
                    // }
                    // if(resp.length && resp.length==1) {
                    if (cNames.includes(getValues("company_name"))) {
                        console.log("2")
                        setIsVerified(1)
                    }
                    else {
                        if (getValues('company_type') == "2") {
                            console.log("3")
                            setIsVerified(0)
                            resetCompanyInfo()
                        }
                        else {
                            console.log("33")
                            setIsVerified(2)
                            resetCompanyInfo()
                        }
                    }
                }
                else {
                    if (getValues('company_type') == "2") {
                        console.log("4")
                        setIsVerified(0)
                        resetCompanyInfo()
                    }
                    else {
                        console.log("5")
                        setIsVerified(2)
                        resetCompanyInfo()
                    }
                }
                if (check) {
                    // if(resp.length && resp.length==1) {
                    // if(resp.length) {
                    if (cNames.includes(getValues("company_name"))) {
                        console.log("6")
                        setIsVerified(1)
                    }
                    else {
                        if (getValues('company_type') == "2") {
                            console.log("7")
                            setIsVerified(0)
                            // resetCompanyInfo()
                        }
                        else {
                            console.log("8")
                            setIsVerified(2)
                            // resetCompanyInfo()
                        }
                    }
                    setShowList(false)
                }
                // else {
                //     if(!resp.length) {
                //         setIsVerified(2)
                //     }
                // }
            }, (message: string) => {

            })
    }

    const removeAnother = () => {
        localStorage.removeItem("another")
    }

    const onChangeRadio = (e: any) => {
        let x = getValues("company_name")
        if (x.length) {
            getCompanyList(x, true)
        }
    }

    const openAskAnaModal = () => {
        eventAction.showAnnaModal(true)
    }

    const closeAskAnnaModal = () => {
        eventAction.showAnnaModal(false)
    }

    useEffect(() => {
        if (claim_id) {
            getGetIncompleteList()
        }
        console.log("Entered this page")
        return () => {
            eventAction.resetShortCodeDetails()
        }
    }, [])

    useEffect(() => {

        if (incompleteClaimList) {
            setValue('contact_person', incompleteClaimList.contact_person ? incompleteClaimList.contact_person : userDetails?.full_name);
            setValue('company_name', incompleteClaimList.company_name ? incompleteClaimList.company_name : '');
            if (incompleteClaimList.company_name) {
                if (incompleteClaimList.is_verified == 1) {
                    setIsVerified(incompleteClaimList.is_verified)
                } else {
                    if (incompleteClaimList.company_type == '1') {
                        setIsVerified(2)
                    } else {
                        setIsVerified(0)
                    }
                }
            }
            if (incompleteClaimList.reg_no) {
                getDirectorsList(incompleteClaimList.reg_no)
            }
            // if(incompleteClaimList.director) {
            //     console.log("gggggggggggggggggggggggg", incompleteClaimList.director, incompleteClaimList.is_verified)
            //     setValue('director', (incompleteClaimList.is_verified==1) ? {label: incompleteClaimList.director, value: incompleteClaimList.director} : incompleteClaimList.director)
            // }
            setValue('reg_no', incompleteClaimList.reg_no ? incompleteClaimList.reg_no : '');
            // console.log("gggggggggggggggggggggggg")
            setValue('director', incompleteClaimList.director ? incompleteClaimList.director : '');
            setValue('company_website', incompleteClaimList.company_website ? incompleteClaimList.company_website : '');
            setValue('registered_address', incompleteClaimList.registered_address ? incompleteClaimList.registered_address : '');
            setValue('contact_email', incompleteClaimList.contact_email ? incompleteClaimList.contact_email : userDetails?.email);
            setValue('contact_number', incompleteClaimList.contact_number ? incompleteClaimList.contact_number : '');
            setValue('company_type', incompleteClaimList.company_type ? String(incompleteClaimList.company_type) : '1')
            setValue('street', incompleteClaimList?.street ? incompleteClaimList.street : '')
            setValue('city', incompleteClaimList?.city ? incompleteClaimList.city : '')
            setValue('country', incompleteClaimList?.country ? incompleteClaimList.country : '')
            setValue('postal_code', incompleteClaimList?.postal_code ? incompleteClaimList.postal_code : '')
            // setValue('first_Name_sc', incompleteClaimList?.first_Name_sc ? incompleteClaimList.first_Name_sc : '')
            // setValue('last_Name_sc', incompleteClaimList?.last_Name_sc ? incompleteClaimList.last_Name_sc : '')
            // setValue('email_sc', incompleteClaimList?.email_sc ? incompleteClaimList.email_sc : '')
            // setValue('phone_sc', incompleteClaimList?.phone_sc ? incompleteClaimList.phone_sc : '')
            // setValue('company_phone', incompleteClaimList.company_phone ? incompleteClaimList.company_phone : '');
            // setValue('phone_no', incompleteClaimList.phone_no ? incompleteClaimList.phone_no : '');
            // setValue('email', incompleteClaimList.email ? incompleteClaimList.email : '');
        }
        else {
            setValue('contact_person', userDetails?.full_name ? userDetails?.full_name : '');
            setValue('contact_email', userDetails?.email ? userDetails?.email : '');
        }
    }, [incompleteClaimList])

    useEffect(() => {
        console.log({ companyList })
    }, [companyList])

    useEffect(() => {
        console.log({ companyNameList })
    }, [companyNameList])

    useEffect(() => {
        if (directorsList && incompleteClaimList && incompleteClaimList.director && isVerified) {
            let isPresentInDirectorList = directorsList.find((x: any) => x.name == incompleteClaimList.director)
            // console.log("dddddddddddddddd")
            setValue('director', (isVerified == 1) ? isPresentInDirectorList ? { label: incompleteClaimList.director, value: incompleteClaimList.director } : '' : incompleteClaimList.director)
        }
    }, [directorsList, incompleteClaimList, isVerified])

    useEffect(() => {
        // if(isVerified==1 && companyList) {       
        //     getDirectorsList(companyList[0].reg_no)
        // }
    }, [isVerified])

    return (
        <div className="login-sec claim-sec">
            <div className="row">
                {anotherCheck == "another" ?

                    <Heading
                        step="1"
                        headingText={`Claim ${claimNo}`}
                        paraText="Enter the details of the claim below"
                        icon={false}
                    />
                    :
                    <Heading
                        step="1"
                        // headingText={`Claim ${incompleteClaimList?.position ? incompleteClaimList?.position : claimNo}`}
                        headingText={`Claim ${claim_id && incompleteClaimList?.position ? incompleteClaimList?.position : claimNo}`}
                        paraText="Enter the details of the claim below"
                        icon={false}
                    />
                }
                <form onSubmit={handleSubmit(onRegistrationFormSubmit)} noValidate>
                    <div className="login-form">
                        <>
                            <label className="project-label">Company Type<span style={{ color: 'red' }}>*</span></label>
                            <div className="form-radio-btn">
                                <Controller
                                    control={control}
                                    name="company_type"
                                    render={({ onChange, onBlur, value, name, ref }) => (
                                        <RadioInput
                                            name={name}
                                            onChange={(e) => {
                                                onChange(e)
                                                onChangeRadio(e)
                                            }
                                            }
                                            disabled={id ? true : false}
                                            onBlur={onBlur}
                                            inputRef={ref}
                                            value={value}
                                            options={COMPANY_TYPE_RADIO}
                                        />
                                    )}
                                />

                            </div>
                            {errors && errors.company_type && errors.company_type.message && <div className="invalid-feedback">{errors.company_type.message}</div>}
                            <label className="project-label">Company Name as listed on Companies House <span style={{ color: 'red' }}>*</span></label>
                            {/* <label className="project-label">Company Name <span style={{ color: 'red' }}>*</span></label> */}
                            {/* <div className="form-line com-status"> */}
                            <div className="form-line">
                                <Controller
                                    control={control}
                                    name="company_name"
                                    render={({ onChange, onBlur, value, name, ref }) => (
                                        <FormTextInput
                                            name={name}
                                            onChange={e => {
                                                onChange(e)
                                                debouncedChangeHandler(e)
                                            }}
                                            onBlur={onBlur}
                                            value={value}
                                            inputRef={ref}
                                            type="text"
                                            error={errors.company_name}
                                            className={isVerified == 2 ? "" : "field-" + (isVerified == 1 ? "" : "un") + "verified"}
                                        // placeholder="Your Company Name"
                                        />
                                    )}
                                />
                                <em></em>
                            </div>
                            {/* <div className={"row " + (companyNameList && companyNameList.length ? "company-list-active" : "")}>
                                {companyNameList && companyNameList.length ? */}
                            <div className={"row " + (companyNameList && companyNameList.length && showList ? "company-list-active" : "")}>
                                {companyNameList && companyNameList.length && showList ?
                                    companyNameList.map((c: any) =>
                                        <div className="col-12" key={c.id} onClick={() => setNameAddressFields(c)}>
                                            {c.company_name}
                                        </div>
                                    )
                                    : null
                                }
                            </div>
                            {/* <label className="project-label">Registered Address <span style={{ color: 'red' }}>*</span></label>
                            <Controller
                                control={control}
                                name="registered_address"
                                className="form-project mb-12 h-auto"
                                render={({ onChange, onBlur, value, name, ref }) => (
                                    <FormTextAreaInput
                                        name={name}
                                        onChange={onChange}
                                        onBlur={onBlur}
                                        value={value}
                                        inputRef={ref}
                                        type="text"
                                        error={errors.registered_address}
                                        // placeholder="Enter your Registered Address"
                                        rows={5}
                                    />
                                )}
                            /> */}
                            <label className="project-label">Street <span style={{ color: 'red' }}>*</span></label>
                            <Controller
                                control={control}
                                name="street"
                                className="form-project mb-12 h-auto"
                                render={({ onChange, onBlur, value, name, ref }) => (
                                    <FormTextInput
                                        name={name}
                                        onChange={onChange}
                                        onBlur={onBlur}
                                        value={value}
                                        inputRef={ref}
                                        type="text"
                                        error={errors.street}
                                    // placeholder="Enter your Street"
                                    />
                                )}
                            />
                            <label className="project-label">City <span style={{ color: 'red' }}>*</span></label>
                            <Controller
                                control={control}
                                name="city"
                                className="form-project mb-12 h-auto"
                                render={({ onChange, onBlur, value, name, ref }) => (
                                    <FormTextInput
                                        name={name}
                                        onChange={onChange}
                                        onBlur={onBlur}
                                        value={value}
                                        inputRef={ref}
                                        type="text"
                                        error={errors.city}
                                    // placeholder="Enter your city"
                                    />
                                )}
                            />
                            <label className="project-label">Country <span style={{ color: 'red' }}>*</span></label>
                            <Controller
                                control={control}
                                name="country"
                                className="form-project mb-12 h-auto"
                                render={({ onChange, onBlur, value, name, ref }) => (
                                    <FormTextInput
                                        name={name}
                                        onChange={onChange}
                                        onBlur={onBlur}
                                        value={value}
                                        inputRef={ref}
                                        type="text"
                                        error={errors.country}
                                    // placeholder="Enter your country"
                                    />
                                )}
                            />
                            <label className="project-label">Postal Code <span style={{ color: 'red' }}>*</span></label>
                            <Controller
                                control={control}
                                name="postal_code"
                                className="form-project mb-12 h-auto"
                                render={({ onChange, onBlur, value, name, ref }) => (
                                    <FormTextInput
                                        name={name}
                                        onChange={onChange}
                                        onBlur={onBlur}
                                        value={value}
                                        inputRef={ref}
                                        type="text"
                                        error={errors.postal_code}
                                    // placeholder="Enter your Postal Code"
                                    />
                                )}
                            />
                        </>
                        <div className="row rsp-column">
                            <div className="col-sm-12">
                                <label className="project-label">Reg. No
                                    {watch('company_type') == '2' ?
                                        <span style={{ color: 'red' }}>*</span>
                                        : null
                                    }
                                </label>
                                <Controller
                                    control={control}
                                    name="reg_no"
                                    className="form-project mb-12"
                                    render={({ onChange, onBlur, value, name, ref }) => (
                                        <FormTextInput
                                            name={name}
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            value={value}
                                            inputRef={ref}
                                            type="text"
                                            // placeholder="Enter your Registration No"
                                            error={watch('company_type') == '2' ? errors.reg_no : ""}
                                        />
                                    )}
                                />
                            </div>
                            {isVerified == 1 && directorsList ?
                                <div className="col-sm-12 mb-12">
                                    <label className="project-label">Director <span style={{ color: 'red' }}>*</span></label>
                                    <Controller
                                        control={control}
                                        name="director"
                                        render={({ onChange, onBlur, value, name, ref }) => (
                                            <SelectInput
                                                name={name}
                                                onChange={onChange}
                                                onBlur={onBlur}
                                                value={value}
                                                inputRef={ref}
                                                options={directorsList.map((d: any) => ({
                                                    value: d.name,
                                                    label: d.name,
                                                }))}
                                                error={errors.director}
                                                placeholder="Select Director"
                                            />
                                        )}
                                    />
                                </div>
                                :
                                <div className="col-sm-12">
                                    <label className="project-label">Director <span style={{ color: 'red' }}>*</span></label>
                                    <Controller
                                        control={control}
                                        name="director"
                                        className="form-project mb-12"
                                        render={({ onChange, onBlur, value, name, ref }) => (
                                            <FormTextInput
                                                name={name}
                                                onChange={onChange}
                                                onBlur={onBlur}
                                                value={value}
                                                inputRef={ref}
                                                type="text"
                                                // placeholder="Enter Director Name"
                                                error={errors.director}
                                            />
                                        )}
                                    />
                                </div>
                            }

                            {/* <div className="col-sm-12">
                                <label className="project-label">Company Phone Number (Optional) </label>
                                <Controller
                                    control={control}
                                    name="company_phone"
                                    className="form-project mb-12"
                                    render={({ onChange, onBlur, value, name, ref }) => (
                                        <FormMaskedInput
                                            name={name}
                                            mask="99999 999999"
                                            maskPlaceholder="99999 999999"
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            value={value}
                                            inputRef={ref}
                                            type="tel"
                                            error={errors.company_phone}
                                        // placeholder="Company Phone Number"
                                        />
                                    )}
                                />
                            </div> */}

                            <div className="col-sm-12">
                                <label className="project-label">Contact Number <span style={{ color: 'red' }}>*</span></label>
                                <Controller
                                    control={control}
                                    name="contact_number"
                                    className="form-project mb-12"
                                    render={({ onChange, onBlur, value, name, ref }) => (
                                        <FormMaskedInput
                                            name={name}
                                            mask="99999 999999"
                                            maskPlaceholder="99999 999999"
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            value={value}
                                            inputRef={ref}
                                            type="tel"
                                            error={errors.contact_number}
                                        // placeholder="Your Contact Number"
                                        />
                                    )}
                                />
                            </div>
                            <div className="col-sm-12 ">
                                <label className="project-label">Company Website (Optional)</label>
                                <Controller
                                    control={control}
                                    name="company_website"
                                    className="form-project mb-12"
                                    render={({ onChange, onBlur, value, name, ref }) => (
                                        <FormTextInput
                                            name={name}
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            value={value}
                                            inputRef={ref}
                                            type="text"
                                            error={errors.company_website}
                                        // placeholder="Your Company Email Address"
                                        />
                                    )}
                                />
                            </div>
                            {/* <div className="col-sm-12 ">
                                <label className="project-label">Company Email <span style={{ color: 'red' }}>*</span></label>
                                <Controller
                                    control={control}
                                    name="email"
                                    className="form-project mb-12"
                                    render={({ onChange, onBlur, value, name, ref }) => (
                                        <FormTextInput
                                            name={name}
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            value={value}
                                            inputRef={ref}
                                            type="text"
                                            error={errors.email}
                                        // placeholder="Your Email Address"
                                        />
                                    )}
                                />
                            </div> */}
                            <div className="col-sm-12 ">
                                <label className="project-label">Contact Email <span style={{ color: 'red' }}>*</span></label>
                                <Controller
                                    control={control}
                                    name="contact_email"
                                    className="form-project mb-12"
                                    render={({ onChange, onBlur, value, name, ref }) => (
                                        <FormTextInput
                                            name={name}
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            value={value}
                                            inputRef={ref}
                                            type="text"
                                            error={errors.contact_email}
                                        // placeholder="Your Company Email Address"
                                        />
                                    )}
                                />
                            </div>
                            <div className="col-sm-12 ">
                                <label className="project-label">Contact Name <span style={{ color: 'red' }}>*</span></label>
                                <Controller
                                    control={control}
                                    name="contact_person"
                                    render={({ onChange, onBlur, value, name, ref }) => (
                                        <FormTextInput
                                            name={name}
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            value={value}
                                            inputRef={ref}
                                            type="text"
                                            error={errors.contact_person}
                                        // placeholder="Your Company Name"
                                        />
                                    )}
                                />
                            </div>
                            {/* <div className="col-sm-12 ">
                                <label className="project-label">First Name SC</label>
                                <Controller
                                    control={control}
                                    name="first_Name_sc"
                                    render={({ onChange, onBlur, value, name, ref }) => (
                                        <FormTextInput
                                            name={name}
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            value={value}
                                            inputRef={ref}
                                            type="text"
                                            error={errors.first_Name_sc}
                                        // placeholder="Your Company Name"
                                        />
                                    )}
                                />
                            </div>
                            <div className="col-sm-12 ">
                                <label className="project-label">Last Name SC</label>
                                <Controller
                                    control={control}
                                    name="last_Name_sc"
                                    render={({ onChange, onBlur, value, name, ref }) => (
                                        <FormTextInput
                                            name={name}
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            value={value}
                                            inputRef={ref}
                                            type="text"
                                            error={errors.last_Name_sc}
                                        // placeholder="Your Company Name"
                                        />
                                    )}
                                />
                            </div>
                            <div className="col-sm-12 ">
                                <label className="project-label">Email SC</label>
                                <Controller
                                    control={control}
                                    name="email_sc"
                                    render={({ onChange, onBlur, value, name, ref }) => (
                                        <FormTextInput
                                            name={name}
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            value={value}
                                            inputRef={ref}
                                            type="text"
                                            error={errors.email_sc}
                                        // placeholder="Your Company Name"
                                        />
                                    )}
                                />
                            </div>
                            <div className="col-sm-12 ">
                                <label className="project-label">Phone SC</label>
                                <Controller
                                    control={control}
                                    name="phone_sc"
                                    render={({ onChange, onBlur, value, name, ref }) => (
                                        <FormMaskedInput
                                            name={name}
                                            mask="99999 999999"
                                            maskPlaceholder="99999 999999"
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            value={value}
                                            inputRef={ref}
                                            type="tel"
                                            error={errors.phone_sc}
                                        // placeholder="Your Contact Number"
                                        />
                                    )}
                                />
                            </div> */}

                            <div className="col-sm-12 col-lg-6">
                                <button type="button" className="project-btn mt-2 resp-left-btn" onClick={saveAndClose}>Save & Close</button>
                            </div>
                            <div className="col-sm-12 col-lg-6">
                                <button type="submit" className="project-btn mt-2 resp-left-btn">Save & Next</button>
                            </div>
                            <div className="col-sm-12 col-lg-12 mt-2 text-center">
                                <Link to={`/my-claims`} onClick={removeAnother}><i className="fa fa-angle-double-left" aria-hidden="true"></i> Go to My Claim</Link>
                            </div>
                        </div>
                    </div>
                </form>
            </div>

            {showAskAnnaModal &&
                <AskAnnaModal
                    shouldShow={showAskAnnaModal}
                    onClose={closeAskAnnaModal}
                    url={VIDEO_URLS.STEP_1}
                    title={"Step 1"}
                />
            }
        </div>
    )
}
