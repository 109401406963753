import React, { useEffect, useState } from 'react'
import Marquee from "react-fast-marquee";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import { useAppUserDetailsSelector } from 'src/_common/hooks/selectors/userSelector'
import { useAppEventStatusMessageSelector, useAppShowAnnaModalSelector } from 'src/_common/hooks/selectors/eventSelector';
import { URLS, STORAGE, SELECTED_EVENT_STORAGE, INSIDERODEO_EVENT_STORAGE, detectDevice, IS_SHOWING_ADD_TO_HOME_SCREEN_IOS, SOCKET_CHANNEL, SOCKET_URL } from 'src/_config';
import { useUserApi } from 'src/_common/hooks/actions/user/appUserApiHook';
import { useToaster } from 'src/_common/hooks/actions/common/appToasterHook';
import { useAppUserAction } from 'src/_common/hooks/actions/user/appUserActionHook';
import { RODEO_PAGE_NAME } from 'src/_config';
import { useAuthStatus } from 'src/_common/hooks/auth/authHook';
import ChatWindow from './ChatWindow';
import useSocket from 'use-socket.io-client';
import { Console } from 'console';
import AskAnnaModal from './AskAnnaModal';
import { useAppEventAction } from 'src/_common/hooks/actions/event/appEventActionHook';

interface SideBarProps {
  page: string;
}

function Sidebar({ page }: SideBarProps) {
  const history = useHistory()
  const userDetails = useAppUserDetailsSelector()
  const statusMessageSelector = useAppEventStatusMessageSelector()
  const userAction = useAppUserAction()
  const userApi = useUserApi()
  const toast = useToaster()
  const location = useLocation()
  const isAuth = useAuthStatus()
  const isInsideRodeoTokenExists = localStorage.getItem(INSIDERODEO_EVENT_STORAGE)
  const [isShowIosPopup, setIsShowIosPopup] = useState<any>(localStorage.getItem(IS_SHOWING_ADD_TO_HOME_SCREEN_IOS) ? parseInt(localStorage.getItem(IS_SHOWING_ADD_TO_HOME_SCREEN_IOS) || '0') : 1)

  const eventAction = useAppEventAction()
  const showAskAnnaModal = useAppShowAnnaModalSelector()
  // const [showAskAnnaModal, setShowAskAnnaModal] = useState<boolean>(false)
  const [showChatWindow, setShowChatWindow] = useState<boolean>(false)
  const [showNewMessageIcon, setShowNewMessageIcon] = useState<boolean>(false)
  const newMsg = localStorage.getItem("newMsg")

  const toogleSideBarOnOff = (status: boolean) => {
    var sidebarCollapseElement = document.getElementById("sidebarCollapse");
    var sidebarElement = document.getElementById("sidebar");
    var overlayElement = document.getElementById("overlay");
    if (status) {
      if (sidebarCollapseElement) {
        sidebarElement?.classList.add("active");
        overlayElement?.classList.add("active");
      }
    } else {
      if (sidebarCollapseElement) {
        sidebarElement?.classList.remove("active");
        overlayElement?.classList.remove("active");
      }
    }
  }

  // const toogleProfileMenuBarOnOff = () => {
  //   var navbarCollapseElement = document.getElementById("navbarCollapse");
  //   setShowHeaderMenu(!showHeaderMenu)
  //   if (showHeaderMenu) {
  //     if (navbarCollapseElement) {
  //       navbarCollapseElement.classList.add("show")
  //     }
  //   } else {
  //     if (navbarCollapseElement) {
  //       navbarCollapseElement.classList.remove("show")
  //     }
  //   }
  // }

  const logOut = (e: React.MouseEvent) => {
    userApi.callLogout((message: string, resp: any) => {
      if (resp) {
        toogleSideBarOnOff(false)
        userAction.logout()
        // localStorage.removeItem("newMsg")
        // localStorage.removeItem("claimNo")
        // localStorage.removeItem("another")
        // localStorage.removeItem(STORAGE)
        // localStorage.removeItem(SELECTED_EVENT_STORAGE)
        // localStorage.removeItem(INSIDERODEO_EVENT_STORAGE)
        history.push(`${URLS.LOGIN}`)
      } else {
        toast.error(message)
      }
    }, (message: string) => {
      userAction.logout()
      // toast.error(message)
    })
  }

  const goToMyAccount = () => {
    history.push(URLS.USER.PROFILE)
  }

  const closeIosPopup = (e: any) => {
    e.preventDefault()
    setIsShowIosPopup(0)
    localStorage.setItem(IS_SHOWING_ADD_TO_HOME_SCREEN_IOS, '0')
  }

  const verifyWithOtp = () => {
    if (userDetails) {
      let params = {
        email: userDetails.email
      }
      userApi.callVerifyWithOtp(
        params,
        (message: any, resp: any) => {
          history.push(URLS.VERIFY)
          toast.success(message)
        },
        (message: any, resp: any) => {
          toast.error(message)
        },
      )
    }
  }

  const removeAnother = () => {
    localStorage.removeItem("another")
  }

  const openAskAnaModal = () => {
    // setShowAskAnnaModal(true)
    eventAction.showAnnaModal(true)
  }

  const closeAskAnaModal = () => {
    // setShowAskAnnaModal(false)
    eventAction.showAnnaModal(false)
  }

  const openChatWindow = () => {
    setShowChatWindow(true)
    setShowNewMessageIcon(false)
    localStorage.removeItem("newMsg")
  }

  const closeChatWindow = () => {
    setShowChatWindow(false)
    setShowNewMessageIcon(false)
    localStorage.removeItem("newMsg")
  }

  //Socket Implementation start here
  const [socket] = useSocket(SOCKET_URL, {
      autoConnect: false,
  });

  useEffect(() => {
      socket.connect()
      
      socket.on(SOCKET_CHANNEL.MESSAGE_SEND, (data: any) => {
        if(data?.room_details?.user_id==userDetails?.id) {
          // if(!showChatWindow) {
            setShowNewMessageIcon(true)
            localStorage.setItem("newMsg","1")
          // }
        }
      });

      socket.on(SOCKET_CHANNEL.MESSAGE_DESTROY, (data: any) => {
        if(data?.room_details?.user_id==userDetails?.id) {
          console.log({data})
        }
      });
      
      socket.on(SOCKET_CHANNEL.MESSAGE_READ, (data: any) => {
        if(data?.room_details?.user_id==userDetails?.id) {
          console.log({data})
          if(data?.is_read==1 && showChatWindow) {
            setShowNewMessageIcon(false)
            localStorage.removeItem("newMsg")
          }
        }
      });

      // socket.on(SOCKET_CHANNEL.MESSAGE_PERMISSION, (data: any) => {
      //   console.log({data})
      //   if(data?.user_id==userDetails?.id) {
      //     console.log({data})
      //   }
      // });

      return () => {
          socket.disconnect()
      }
  }, [socket])


  useEffect(() => {
    if (location.pathname != "/") {
      let x = document.getElementById('navbarNavAltMarkup')
      if (x) {
        x.style.backgroundColor = ""
      }
    }
  }, [location])

  useEffect(()=>{
    if(newMsg && newMsg=="1") {
      setShowNewMessageIcon(true)
    }
  },[newMsg])

  useEffect(()=>{
    if(userDetails?.message_room_details?.is_read=="0") {
      setShowNewMessageIcon(true)
    }
  },[userDetails])

  return (
    <>
      <div className="navbar-in">
        <div className="navigation">
          <nav className="navbar navbar-expand-lg navbar-light  ">
            <div className=" container-fluid">

              <Link to={isAuth ? URLS.EVENT.MY_CLAIMS : URLS.LOGIN} className="navbar-brand"><img src="/images/HEADER LOGO.png" alt="logo" /></Link>
              {window.screen.width<=820 && !isAuth &&
                <div className="msg-icon-wrap">
                  <a onClick={openAskAnaModal} style={{ color: "white", cursor: "pointer", right: "60px" }}>
                    <img src="/images/ask-ana.png" width="22" title="Ask Ana"/> Ask Ana
                  </a>
                </div>}
              {window.screen.width<=820 && isAuth && <div className="msg-icon-wrap">
                <a onClick={openAskAnaModal} style={{ color: "white", cursor: "pointer" }}>
                  <img src="/images/ask-ana.png" width="22" title="Ask Ana"/> Ask Ana
                </a>
                <i className="fa-solid fa-message nav-link msg-icon" onClick={openChatWindow}/>
                {showNewMessageIcon && <span className="msg-noti"></span>}</div>
              }
              <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
                {/* <span className="navbar-toggler-icon"></span> */}
                <span className="fa fa-bars"></span>
              </button>
              {
                userDetails && !userDetails.verified &&
                <div className="alert alert-warning mt-3">
                  Email is not verified.{" "}
                  <a onClick={verifyWithOtp} style={{ cursor: "pointer" }}>
                    Click here to verify
                  </a>
                </div>
              }  
              <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
                <div className="navbar-nav">
                  {isAuth ?
                    <>
                      <Link to={{ pathname: "https://123claim.co.uk/dashboard-quick-guide/" }} target="_blank" className="nav-link">Dashboard Quick Guide</Link>
                      <Link to={URLS.EVENT.MY_CLAIMS} onClick={() => { removeAnother(); setShowChatWindow(false); }} className="nav-link">My Claims</Link>
                    </>
                    :
                    <Link to={{ pathname: "https://123claim.co.uk/dashboard-quick-guide/" }} target="_blank" className="nav-link">Dashboard Quick Guide</Link>
                  }
                  <Link to={URLS.CMS.CONTACT_US} className="nav-link" onClick={()=>setShowChatWindow(false)}>Request Info</Link>
                  {window.screen.width>820 && !isAuth && 
                      <a onClick={openAskAnaModal} style={{ cursor: "pointer" }}>
                        <img src="/images/ask-ana.png" width="22" title="Ask Ana" /> Ask Ana
                      </a>
                  }
                  {
                    userDetails ?
                      <>
                      {/* <a href="javascript:void(0);" className="dropdown-toggle" data-bs-toggle="dropdown" data-bs-display="static" aria-expanded="false">
                        <img src="https://cdn-icons-png.flaticon.com/128/64/64572.png" alt="Edit"></img> Admin (superadmin@yopmail.com)
                      </a> */}
                        {/* <div className="chat-grp"></div> */}
                        
                        {window.screen.width>820 && isAuth && 
                          <>
                            <a onClick={openAskAnaModal} style={{ cursor: "pointer" }}>
                              <img src="/images/ask-ana.png" width="22" title="Ask Ana" /> Ask Ana
                            </a>
                            <i className="fa-solid fa-message nav-link" style={{ color: "white",cursor:"pointer" }} onClick={openChatWindow}/>
                            {showNewMessageIcon && <span className="msg-noti"></span>}
                          </>
                        }
                        <div className="btn-group">
                          <a className="dropdown-toggle" data-bs-toggle="dropdown" data-bs-display="static" aria-expanded="false" style={{cursor:"pointer"}}>
                            <img src="/images/profile-icon.png" alt="Edit" /> {userDetails?.full_name}
                            {/* ({userDetails?.email}) */}
                          </a>
                          {/* <a
                            href="javascript:void(0);"
                            className="dropdown-toggle"
                            data-bs-toggle="dropdown"
                            data-bs-display="static"
                            aria-expanded="false"
                          >
                            Masters
                          </a> */}
                          <ul className="dropdown-menu dropdown-menu-lg-end">
                            <li>
                              {/* <a
                                href="https://class-adminv1.dreamztesting.com/sales-persons"
                                id="menu-27"
                                className="dropdown-item"
                              >
                                Sales person
                              </a> */}
                              <a className="dropdown-item" onClick={(e) => logOut(e)} style={{cursor:"pointer"}}>Logout</a>
                            </li>
                          </ul>
                        </div>

                      </>
                      : <Link to={URLS.LOGIN} className="nav-login-link">Login</Link>
                  }
                </div>
              </div>
            </div>
          </nav>
        </div>
      </div>
      {showChatWindow && <ChatWindow onClose={closeChatWindow} setShowNewMessageIcon={setShowNewMessageIcon}/>}
      {/* {showAskAnnaModal && 
        <AskAnnaModal 
          shouldShow={showAskAnnaModal}
          onClose={closeAskAnaModal}
          url={"step3.mp4"}
        />
      } */}
    </>
  )
}

export default Sidebar
